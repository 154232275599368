import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

export const pageQuery = graphql`
  query LinkQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
		subtitle
		abstract
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 200, quality: 80, srcSetBreakpoints: [960, 1440]
					) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
const LinkPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark
    const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""

	return (
		<Layout className="page">
			<SEO
				title={frontmatter.title}
				description={excerpt}
			/>
			<div className="wrapper">
				{Image ? (
          		  <Img 
          		    fluid={Image} 
          		    alt={frontmatter.title + ' - Featured image'}
          		    className="top-page-image"
					fadeIn={false}
          		  />
          		) : ""}
				<h1>{frontmatter.title}</h1>
				<Content source={frontmatter.abstract} />
				{frontmatter.subtitle && <h3>{frontmatter.subtitle}</h3>}
				<article dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		</Layout>
	)
}

export default LinkPage
